<template>
  <div class="flex flex-col items-center justify-between pt-4 sm:flex-row">
    <div class="flex items-center">
      <font-awesome-icon
        class="mr-3 text-2xl"
        :icon="['fal', 'calendar-day']"
      />{{
        subscription.isOnTrial
          ? 'Want your first months box now? No worries, get your food sent out earlier '
          : 'Running low on food? No worries, get your food sent out earlier'
      }}
    </div>
    <button
      class="mt-4 block w-full flex-none appearance-none rounded bg-yellow-200 px-3 py-2 text-center font-semibold hover:bg-yellow-200 sm:mt-0 sm:inline-block sm:w-48"
      @click="showModal('', 'FoodForward', subscription)"
    >
      <font-awesome-icon class="mr-2" :icon="['fal', 'truck']" />
      Send my food!
    </button>
  </div>
</template>

<script>
export default {
  props: ['subscription'],
  methods: {
    showModal: function (title, component, data) {
      data.fromUnpause = false;
      this.$store.commit('show_modal', {
        title: title,
        component: component,
        data: data,
        outerClose: false
      });
    }
  }
};
</script>

<style lang="css" scoped></style>
