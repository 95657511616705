<template>
  <div class="container mx-auto py-4 pt-0 sm:pt-4">
    <dashboard-nav v-if="user.hadSubscription" />

    <div class="grid grid-cols-3 gap-4">
      <div
        class="col-span-3"
        v-if="user.hasSubscription || user.hadSubscription"
      >
        <feature-banner :features="features" />
      </div>
      <div class="col-span-3" v-if="currentSamples && user.hasSubscription">
        <sample-tracker />
      </div>
      <div class="col-span-3">
        <transition name="fade" mode="out-in">
          <subscription v-if="user" key="subscription-section" />
        </transition>
      </div>
      <div v-if="user.hadSubscription" class="col-span-3 sm:col-span-1">
        <pouch-counter :current="user.shelterpouches" />
      </div>
      <div
        :class="
          user.hadSubscription ? 'col-span-3 sm:col-span-2' : 'col-span-3'
        "
      >
        <referral v-if="user.hadSubscription" />
      </div>
    </div>
  </div>
</template>

<script>
import PouchCounter from '@/components/PouchCounter';
import Referral from '@/components/Referral';
import Subscription from '@/components/subscription/Subscription';
import DashboardNav from '@/components/DashboardNav';
import { mapGetters, mapActions } from 'vuex';
import FeatureBanner from '@/components/FeatureBanner.vue';
import features from '@/components/feature/features';
import SampleTracker from '@/components/samples/SampleTracker.vue';
export default {
  metaInfo: {
    title: 'Dashboard'
  },
  data() {
    return {
      features
    };
  },
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
      currentSamples: 'currentSamplesGetter',
      currentSubscription: 'getCurrentSubscription'
    })
  },
  methods: {
    ...mapActions({
      addSample: 'addSample'
    })
  },
  mounted() {
    this.$store.dispatch('getUser');
  },
  components: {
    PouchCounter,
    Subscription,
    Referral,
    DashboardNav,
    FeatureBanner,
    SampleTracker
  }
};
</script>

<style SampleTracker lang="css" scoped></style>
