<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="user"
      :key="activeFeature.id"
      class="relative flex items-center p-4 space-x-4 py-4 pb-4 rounded-lg border-2 "
      :class="`bg-${activeFeature.color}-100 border-${activeFeature.color}-400`"
    >
      <div class="flex-grow relative w-full  ">
        <div class="w-3/5 sm:w-4/5 flex-col space-y-1 z-20 relative">
          <div class="font-semibold flex space-x-2 ">
            <span
              v-if="activeFeature.isNew"
              class="rounded-full bg-pink-400 text-xs text-white uppercase font-sans px-3 py-1 "
              >New</span
            >
            <div class="font-semibold text-lg">{{ activeFeature.title }}</div>
          </div>
          <div class="text-sm">{{ activeFeature.description }}</div>
          <div
            v-if="activeFeature.link"
            class="flex items-center justify-between text-sm"
          >
            <div
              @click="addToBox"
              :class="
                `cursor-pointer border-b-2 py-1 px-1 border-${activeFeature.color}-400 hover:bg-${activeFeature.color}-200`
              "
              v-html="activeFeature.ctaCopy"
            ></div>
            <!-- <div
              class="sm:hidden self-start p-1 pr-2  text-gray-400 flex space-x-1 items-center text-xl  flex-none rounded-full hover:bg-gray-200 cursor-pointer leading-none"
              @click="closeFeature"
            >
              <font-awesome-icon :icon="['fal', 'circle-xmark']" />
              <div class="text-base text-gray-500">Hide</div>
            </div> -->
          </div>
        </div>

        <div class="absolute  right-0 bottom-0 -mb-3 w-48 -mr-4 sm:-mr-0">
          <img
            v-if="activeFeature.image"
            class="w-full"
            :src="`/images/features/${activeFeature.image}.webp`"
          />
        </div>
      </div>
      <div
        class="hidden self-start p-1 pr-2  text-gray-400 sm:flex space-x-1 items-center text-xl  flex-none rounded-full hover:bg-gray-200 cursor-pointer leading-none"
        @click="closeFeature"
        v-if="activeFeature.canClose"
      >
        <font-awesome-icon :icon="['fal', 'circle-xmark']" />
        <div class="text-base text-gray-500">Hide</div>
      </div>
    </div>
  </transition>
</template>

<script>
import { uniq, includes } from 'lodash/fp';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
export default {
  name: 'FeatureBanner',
  props: {
    features: {
      type: Array
    }
  },
  data() {
    return { hiddenFeatures: [] };
  },
  computed: {
    ...mapGetters({
      user: 'getCurrentUser'
    }),
    today() {
      return format(new Date(), 'dd/MM/yyyy');
    },
    activeFeature() {
      return this.filtered[0];
    },
    filtered() {
      return this.features.filter(f => !includes(f.id, this.hiddenFeatures));
    }
  },
  created() {
    this.hiddenFeatures = JSON.parse(localStorage.getItem('ccc_ft_h')) || [];
  },
  methods: {
    closeFeature() {
      this.hiddenFeatures.push(this.activeFeature.id);
      const vals = JSON.stringify(uniq(this.hiddenFeatures));
      localStorage.setItem('ccc_ft_h', vals);
    },
    addToBox() {
      this.$store.dispatch('sendMpEvent', {
        event: 'Feature CTA',
        properties: {
          location: this.$route.name,
          type: 'EVT',
          id: this.activeFeature.id,
          action: this.activeFeature.ctaCopy
        }
      });

      this.$router.push({
        name: this.activeFeature.link,
        params: { id: this.activeFeature.scrollId }
      });
    }
  }
};
</script>

<style></style>
