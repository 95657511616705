<template>
  <div
    class="w-full  sm:flex  sm:flex-row items-center justify-center space-y-2 sm:space-y-0 text-center text-sm px-3 rounded"
  >
    <div class="flex items-center justify-center">
      You have
      <span
        class="font-semibold space-x-2 mx-1 relative items-center inline-flex p-0.5 px-2 rounded bg-blue-100 text-blue-600 "
        ><font-awesome-icon class="text-blue-600" :icon="['far', 'coins']" />
        <div>£{{ availableCredit }}</div></span
      >
      credit available.
    </div>
    <div v-if="hasCredit" class="mx-1 flex justify-center sm:inline-flex">
      This will be taken off your next box.
    </div>
    <div class="mx-1 flex justify-center sm:inline-flex">
      <span class="underline " @click="showModal('', 'EarnCredit', null)"
        >How can I earn credit?</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      subscription: 'getCurrentSubscription'
    }),
    availableCredit() {
      return (this.subscription.credit / 100).toFixed(2);
    },
    hasCredit() {
      return this.subscription.credit > 0;
    }
  },
  methods: {
    showModal: function(title, component, data) {
      this.$store.commit('show_modal', {
        title: title,
        component: component,
        data: data
      });
    }
  }
};
</script>

<style></style>
