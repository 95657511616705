<template>
  <button
    class="  w-full sm:w-48 flex-none mt-4 sm:mt-0 bg-red-400 hover:bg-red-500 appearance-none text-center font-semibold text-white rounded py-2 px-3 block sm:inline-block   "
    @click="showModal('Make a payment', 'FoodForward', subscription)"
  >
    Make a payment
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      subscription: 'getCurrentSubscription'
    })
  },
  methods: {
    showModal: function(title, component, data) {
      const dataObject = {
        ...data,
        fromUnpause: false,
        isAttemptPayment: true
      };

      this.$store.commit('show_modal', {
        title: title,
        component: component,
        data: dataObject,
        outerClose: false
      });
    }
  }
};
</script>

<style lang="css" scoped></style>
