<template>
  <div
    class="flex-col items-start space-y-4 rounded-lg border-2 p-4 sm:flex sm:flex-row sm:space-x-4 sm:space-y-0"
  >
    <div class="flex-grow space-y-2">
      <div class="space-y-2">
        <div class="flex items-center space-x-2 text-lg font-semibold">
          <span
            class="rounded-full bg-pink-400 px-3 py-1 font-sans text-xs uppercase text-white"
            >New</span
          >
          <div>
            {{
              subscription.areSamplesFree
                ? 'Try on us...'
                : 'Try something new...'
            }}
          </div>
        </div>
        <div class="w-full text-sm text-gray-700 sm:w-96">
          <div v-if="subscription.areSamplesFree">
            Maximise your cats happiness by finding more items they might like.
            Try up to 3 different samples, for free in your next box.
          </div>
          <div v-else>
            Maximise your cats happiness by finding more items they might like.
            Try up to 3 different samples, for 75p each in your next box.
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-between space-x-4 sm:justify-start sm:space-x-2"
      >
        <div
          :key="sample.sku"
          class="flex h-24 w-24 items-center justify-center sm:h-16 sm:w-16"
          v-for="sample in activeSamples"
        >
          <image-getter
            classes="
                object-cover rounded-full w-full h-full
              "
            :url="getImageUrl(sample.image.food, 'ar-1_1,w-200')"
            default="/images/product/awaitingimage.webp"
          />
        </div>
        <div
          v-for="index in samplesLeft"
          :key="`no-sample-${index}`"
          class="flex h-24 w-24 items-center justify-center rounded-full bg-gray-100 text-xl font-semibold text-gray-400 sm:h-16 sm:w-16"
        >
          {{ [3, 2, 1][samplesLeft - index] }}
        </div>
      </div>
    </div>

    <div class="flex-grow">
      <div v-if="activeSamples.length > 0" class="pb-1 font-semibold">
        Your choices this month...
      </div>
      <div
        :key="sample.sku"
        class="flex transform overflow-hidden text-left"
        v-for="sample in activeSamples"
      >
        <div class="text-sm text-gray-700">
          1 x {{ sample.name }} <span v-if="sample.isKitten">(Kitten)</span>
        </div>
      </div>
    </div>

    <div
      @click="showModal('', 'AddFreeSample', activeSamples)"
      class="w-full flex-none cursor-pointer rounded bg-yellow-200 px-3 py-2 text-center font-semibold hover:bg-yellow-200 sm:w-48"
    >
      {{
        activeSamples.length === 0 ? 'Choose their samples' : 'Update Choices'
      }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import utils from '@/utils';
import ImageGetter from '../ImageGetter.vue';

export default {
  components: { ImageGetter },
  name: 'SampleTracker',

  data() {
    return {
      activeSamples: []
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProducts',
      samples: 'currentSamplesGetter',
      subscription: 'getCurrentSubscription'
    }),
    samplesLeft() {
      return 3 - this.activeSamples.length;
    },
    samplePrice() {
      return utils.formattedSamplePrice(this.subscription.bonusFood.length);
    }
  },
  methods: {
    ...mapActions({
      addSample: 'addSample',
      removesample: 'removeSample'
    }),
    mapSamples(samples) {
      // create an array of products that are found in products from each item in the currentSamples array
      if (!this.currentSamples && !this.products) return;
      this.activeSamples = samples.map((sample) => {
        return this.products.find((product) => product.sku === sample);
      });
    },
    showModal: function (title, component, data) {
      this.$store.commit('show_modal', {
        title: title,
        component: component,
        data: data,
        props: { isFull: true }
      });
    },
    getImageUrl(url, config) {
      return utils.transformImage(url, config);
    }
  },
  watch: {
    samples: function () {
      this.mapSamples(this.samples);
    }
  },
  mounted() {
    this.currentSamples = this.samples;
    this.mapSamples(this.samples);
  }
};
</script>

<style></style>
