const features = [
  {
    id: 'MIXITUP',
    title: 'Mix it up!',
    description:
      'Mix things up a little or a lot. View our ever-growing range and choose as little as 5 or more of any flavour!',
    link: 'the food',
    ctaCopy: 'View Food &rarr;',
    scrollId: 'food-selection-start',
    image: '5ormore',
    color: 'blue',
    size: 'h-10',
    isActive: true,
    isNew: false,
    canClose: false,
    deadline: '01/06/2023',
    scrollAnchor: ''
  }
  // {
  //   id: 'FTDLNEW',
  //   title: 'Deluxe Fillets',
  //   description: `We’ve added 5 new recipes to our range. Combining pure shredded fillets with our special gravy broth.
  // Simply add 5 or more of any flavour to your monthly order!`,
  //   link: 'adjust pricing',
  //   ctaCopy: 'Add to my box &rarr;',
  //   image: 'new-cans',
  //   color: 'blue',
  //   size: 'h-12',
  //   isActive: false,
  //   isNew: true,
  //   canClose: true,
  //   deadline: '01/06/2023'
  // },
  // {
  //   id: 'DEFAULT',
  //   title: 'Pick & Mix',
  //   description: `Did you know... the minimum of any flavour is just 5 units. Perfect for trying a flavour or giving your cat more variety!`,
  //   link: 'adjust pricing',
  //   ctaCopy: 'Update my box &rarr;',
  //   image: 'pick-mix',
  //   size: 'h-12',
  //   color: 'gray',
  //   isActive: true,
  //   isNew: false,
  //   deadline: null,
  //   canClose: false
  // }
];

export default features;
