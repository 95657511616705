<template>
  <div class="bg-red-100 p-2   w-full h-full  px-2 flex flex-col">
    <div class="  flex  flex-row space-x-4 h-full">
      <div class="w-2 flex-none h-full bg-red-400 rounded"></div>
      <div class="hidden sm:flex h-full w-12 items-center justify-center">
        <font-awesome-icon
          class="text-4xl text-red-400"
          :icon="['fal', 'triangle-exclamation']"
        />
      </div>
      <div class="flex-grow flex flex-col items-start sm:flex-row sm:space-x-4">
        <div>
          <div class="font-semibold">There was a payments issue...</div>
          <div class="text-sm w-full sm:w-4/5">
            {{ error.message }}
            <span v-if="error.willRetryTomorrow"
              >We will try to take payment again tomorrow. You can also attempt
              to make the payment now.</span
            >
            <span v-else>You can attempt to make the payment now.</span>
          </div>
        </div>
        <div class="hidden sm:inline-flex flex-col">
          <attempt-payment />
          <a
            v-if="billingURL"
            class="text-xs py-4 pb-2 text-gray-700 text-center hover:underline"
            :href="billingURL"
            >Update payment details &rarr;</a
          >
        </div>
      </div>
    </div>
    <div class="flex flex-col text-center space-y-2 sm:hidden">
      <attempt-payment />
      <a
        class="text-sm py-3 text-gray-700 "
        v-if="billingURL"
        :href="billingURL"
        >Update payment details &rarr;</a
      >
    </div>
  </div>
</template>

<script>
import AttemptPayment from '../AttemptPayment.vue';
import http from '@/http';
export default {
  components: { AttemptPayment },
  name: 'SubscriptionError',
  props: {
    error: {
      type: Object,
      default: () => {
        return {
          message:
            'There was an issue trying to take payment for this months box',
          code: 'unknown'
        };
      }
    }
  },
  data() {
    return {
      billingURL: null
    };
  },
  methods: {
    getBillingUrl() {
      http
        .get('/updatepayment')
        .then(response => {
          this.billingURL = response.data.content[0].url;
        })
        .catch(() => {
          throw Error("Couldn't get billing URL");
        });
    }
  },

  mounted() {
    this.getBillingUrl();
  }
};
</script>

<style></style>
