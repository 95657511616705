<template>
  <div
    class=" h-full justify-center text-center sm:text-left w-full bg-gray-100 p-4 px-6  rounded-lg border-2 flex items-center sm:justify-start"
  >
    <div class="text-md ">
      You've donated
      <div class="font-semibold text-5xl">{{ current }}</div>
      food pouches to a cat shelter
    </div>
  </div>
</template>

<script>
export default {
  props: ["current"],

  data() {
    return {
      count: this.current ? this.current : 0
    };
  }
};
</script>

<style lang="scss" scoped></style>
