<template>
  <div class="p-4 overflow-hidden pb-2 border-b-2">
    <div class="text-xl w-full flex font-semibold mb-4 items-center">
      <span class="flex-grow sm:flex-none">Your Subscription</span>
      <span
        v-if="subscription.active"
        class=" ml-2 bg-teal-100 text-teal-400 font-normal  rounded px-2 text-base py-0.5"
        >Active</span
      >
      <span
        v-if="!subscription.active"
        class=" ml-2 bg-red-100 text-red-400 font-normal  rounded px-2 text-base py-0.5"
        >Cancelled</span
      >
    </div>
    <div class="flex flex-col sm:flex-row sm:items-end">
      <div class="flex-grow">
        <div
          class="flex sm:space-x-4 flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:divide-x-2"
        >
          <span class="font-semibold text-xl sm:pr-8 ">
            <div v-if="subscription.active">
              {{ subscription.settings.originalSubscription | formatPounds }}
            </div>
            <div v-else>{{ inactiveCost }}</div>

            <div class="text-sm  font-normal text-gray-600">
              / per month
              <span v-if="hasDeliveryCost"
                >(+
                {{ subscription.settings.originalDelivery | formatPounds }}
                delivery)</span
              >
              <span v-if="hasUpgradedDelivery && !isRemote"
                >(Incl. FREE upgraded delivery!)</span
              >
            </div></span
          >
          <span class="font-semibold text-xl sm:pl-8">
            <span v-if="!subscription.payday" class="flex items-center">
              <div>Every 28 days</div>
            </span>
            <span v-else>
              {{ paydayDay }}{{ paydayDay | ordinal }} of every month
            </span>
            <div
              class="text-sm  flex items-center space-x-2 font-normal text-gray-600"
            >
              <span>Frequency</span>
              <span
                class="text-sm underline text-gray-600 font-normal hover:text-gray-800 cursor-pointer"
                v-if="!subscription.isOnTrial && subscription.active"
              >
                <payday-update :subscription="subscription" />
              </span></div
          ></span>
        </div>
      </div>
      <div v-if="subscription.active" class="w-full mt-8 sm:mt-0 sm:w-48">
        <div
          class="block flex-grow w-full"
          @click="
            sendEvent({
              event: 'Cancel Subscription',
              properties: {
                location: $route.name,
                type: 'EVT'
              }
            })
          "
        >
          <router-link
            class=" appearance-none  block text-center text-red-400 font-semibold rounded py-2 px-4 hover:bg-red-400 hover:text-white"
            :to="{
              name: 'cancel confirm',
              params: {
                subscription: subscription
              }
            }"
            >Cancel Subscription</router-link
          >
        </div>
      </div>
    </div>
    <div class="h-20 w-full relative mt-6">
      <div
        class="inset-0 absolute -bottom-4 -ml-4 -mb-12 -mr-4 opacity-30"
        style="background-image: url('images/cat-progress.svg')"
      ></div>
    </div>
  </div>
</template>

<script>
import PaydayUpdate from '@/components/PaydayUpdate';
import { mapGetters, mapActions } from 'vuex';
import { isEmpty } from 'lodash/fp';
import utils from '@/utils';
export default {
  name: 'SubscriptionBanner',
  components: {
    PaydayUpdate
  },
  data() {
    return {
      inactiveCost: false
    };
  },
  computed: {
    ...mapGetters({
      subscription: 'getCurrentSubscription'
    }),
    paydayDay() {
      return new Date(this.subscription.nextbilldatefull).getDate();
    },
    isRemote() {
      return this.subscription?.isRemote;
    },

    activeDelivery() {
      if (!isEmpty(this.subscription?.deliveryType)) {
        const activeDelivery = this.subscription?.deliveryType?.prices.find(
          p => p.id === this.subscription.deliveryTypeSelected
        );

        return activeDelivery;
      } else {
        return false;
      }
    },
    hasUpgradedDelivery() {
      return this.subscription.deliveryTypeFreeUpgrade;
    },
    hasDeliveryCost() {
      const deliveryCost = this.subscription?.deliveryTypeIsPaid
        ? this.subscription.isRemote
          ? 299
          : 199
        : 0;
      // const delivery = this.data.deliveryType.prices.find(
      //   o => o.id === this.data.deliveryTypeSelected
      // );

      if (deliveryCost === 0) {
        return false;
      } else {
        return {
          cost: deliveryCost,
          displayCost: utils.getDisplayCost(deliveryCost, 0, true)
        };
      }
    },
    formattedSubscriptionCost() {
      const deliveryCost = this.subscription?.deliveryTypeIsPaid
        ? this.subscription.isRemote
          ? 299
          : 199
        : 0;
      // const delivery = this.subscription?.deliveryType?.prices.find(
      //   o => o.id === this.subscription.deliveryTypeSelected
      // );
      return utils.getDisplayCost(
        this.subscription.totalCost,
        deliveryCost,
        true
      );
    }
  },
  watch: {
    subscription(newValue) {
      if (newValue) {
        this.getInactiveCost();
      }
    }
  },
  methods: {
    ...mapActions({
      sendEvent: 'sendMpEvent'
    }),
    getInactiveCost() {
      this.$store
        .dispatch('getSubscriptionCost', this.subscription.items)
        .then(response => {
          this.inactiveCost = response.total;
        });
    }
  },
  mounted() {
    if (!this.subscription.active) {
      this.getInactiveCost();
    }
  }
};
</script>

<style></style>
