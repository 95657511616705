import { render, staticRenderFns } from "./FoodForward.vue?vue&type=template&id=aae80e46&scoped=true"
import script from "./FoodForward.vue?vue&type=script&lang=js"
export * from "./FoodForward.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aae80e46",
  null
  
)

export default component.exports