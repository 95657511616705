<template>
  <div v-if="!isLoading">
    <transition name="fade" mode="out-in">
      <div v-if="hadSubscription || hasSubscription" key="subscribed">
        <div class="grid grid-cols-3 gap-4" v-if="!subscriptionError">
          <transition
            enter-active-class="miniFadeInTop"
            leave-active-class="miniFadeOutBottom"
            mode="in-out"
          >
            <div
              v-if="isSuccessUpdate"
              class="default-animation default-transition col-span-3 block h-16 text-left text-teal-600"
            >
              <div
                class="mx-auto flex h-full items-center space-x-3 rounded-lg border-2 border-teal-400 bg-teal-100 px-4"
              >
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="text-xl text-teal-500"
                />
                <div class="text-sm md:text-base">
                  Your subscription has been successfully updated
                </div>
              </div>
            </div>
          </transition>

          <div
            class="col-span-3 flex flex-col overflow-hidden rounded-lg border-2 sm:col-span-3"
          >
            <div
              v-if="latestOrder && !latestOrder.shipped"
              class="mb-0 flex flex-row space-x-4 border-b-2 border-dashed bg-gray-50 p-4 sm:flex-row sm:space-y-0"
              :class="{ 'border-none': subscription.actionNeeded }"
            >
              <div class="h-full w-2 flex-none rounded bg-orange-300"></div>
              <div
                class="flex flex-grow flex-col flex-col-reverse space-y-4 space-y-reverse sm:flex-row sm:space-x-4 sm:space-y-0"
              >
                <div class="flex flex-row items-center">
                  <div class="relative w-14 flex-grow">
                    <font-awesome-icon
                      class="rotate-45 transform text-4xl text-orange-300"
                      :icon="['fal', 'bell-on']"
                    />
                  </div>
                  <div class="flex flex-col text-right text-sm sm:hidden">
                    <div class="font-semibold hover:underline">
                      <router-link
                        :to="{
                          name: 'order history',
                          params: { highlightLatest: true }
                        }"
                        active-class="is-active"
                        exact
                        >#{{ latestOrder.invId }}</router-link
                      >
                    </div>
                    <div class="text-sm text-gray-600">
                      ordered {{ latestOrder.createdAt | timeago }}
                    </div>
                  </div>
                </div>

                <div class="flex-grow">
                  <div class="font-semibold">
                    You have a
                    {{ latestOrder.isSample ? 'Taster Box' : 'monthly box' }}
                    on its way...
                  </div>
                  <div
                    v-if="!latestOrder.isSample"
                    class="text-sm text-gray-700"
                  >
                    Your order is in our Packing &amp; Delivery process and
                    therefore can’t be changed. <br class="hidden sm:inline" />
                    Any changes you make will be reflected in your next box.
                  </div>
                  <div v-else class="text-sm text-gray-700">
                    Your order is in our Packing &amp; Delivery process and it
                    won't be long until its with you!
                  </div>
                  <div class="pt-4">
                    <history-timeline
                      :history="latestOrder"
                      :is-inline="true"
                      :is-shipped="latestOrder.shipped"
                    />
                  </div>
                </div>
              </div>
              <div class="hidden flex-none text-right sm:block">
                <div class="font-semibold hover:underline">
                  <router-link
                    :to="{
                      name: 'order history',
                      params: { highlightLatest: true }
                    }"
                    active-class="is-active"
                    exact
                    >#{{ latestOrder.invId }}</router-link
                  >
                </div>
                <div class="text-sm text-gray-600">
                  ordered {{ latestOrder.createdAt | timeago }}
                </div>
              </div>
            </div>
            <div v-if="subscription.actionNeeded" class="p-2">
              <subscription-error
                :error="subscription.actionNeeded"
                class="rounded"
              />
            </div>
            <div
              class="flex flex-col items-start justify-between rounded-lg p-4 px-4 sm:flex-grow sm:flex-row sm:space-x-4"
            >
              <div
                v-if="subscription.active"
                class="h-full w-2 rounded"
                :class="
                  subscription.actionNeeded ? 'bg-red-400' : 'bg-teal-400'
                "
              ></div>
              <div class="flex flex-grow flex-col">
                <div class="flex flex-col sm:flex-row">
                  <div
                    class="flex h-full flex-col items-center sm:flex-grow sm:flex-row"
                  >
                    <div
                      class="flex h-full w-full space-x-4 sm:mt-0 sm:space-x-0"
                    >
                      <div
                        class="h-full w-2 flex-none rounded sm:hidden"
                        :class="
                          subscription.actionNeeded
                            ? 'bg-red-400'
                            : 'bg-teal-400'
                        "
                      ></div>
                      <div v-if="subscription.active">
                        <div class="mb-4 text-2xl font-semibold">
                          {{
                            !subscription.isPausedIndefinitely
                              ? subscription.isOnTrial
                                ? 'Your first months box...'
                                : 'Your next box...'
                              : 'Welcome back, ready for your next box?'
                          }}
                        </div>

                        <div class="text-gray-700">
                          {{
                            !subscription.isPausedIndefinitely
                              ? `Next payment on ${getPaymentDate()}`
                              : 'Your subscription price will be'
                          }}
                        </div>
                        <div
                          class="mt-1 flex items-center space-x-2 text-2xl font-semibold"
                        >
                          <div
                            :class="{
                              'font-normal italic text-gray-600 line-through':
                                hasCredit ||
                                (hasCoupon && !coupons.onlyDelivery)
                            }"
                          >
                            {{
                              subscription.settings.originalSubscription
                                | formatPounds
                            }}
                          </div>
                          <div
                            v-if="
                              hasCoupon && !hasCredit && !coupons.onlyDelivery
                            "
                          >
                            {{
                              subscription.settings.finalSubscription
                                | formatPounds
                            }}
                          </div>
                          <div v-if="hasCredit">
                            {{
                              subscription.settings.finalSubscription
                                | formatPounds
                            }}
                          </div>

                          <div
                            v-if="discountMessage"
                            class="flex items-center space-x-2"
                          >
                            <div
                              class="relative mx-1 inline-flex hidden items-center space-x-2 rounded bg-blue-100 p-1 px-2 text-sm font-semibold text-blue-600 sm:flex"
                            >
                              <div>{{ discountMessage }}</div>
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="discountMessage"
                          class="relative -ml-1 mt-2 flex inline-flex items-center space-x-2 rounded bg-blue-100 p-1 px-2 text-sm font-semibold text-blue-600 sm:hidden"
                        >
                          <div>{{ discountMessage }}</div>
                        </div>
                        <div
                          class="mt-1 text-sm font-normal text-gray-600"
                          v-if="
                            !hasFreeSamples && subscription.bonusFood.length > 0
                          "
                        >
                          (+ {{ formattedSampleCost }} samples)
                        </div>
                        <div
                          v-if="subscription.deliveryTypeIsPaid"
                          class="mt-1 text-sm font-normal text-gray-600"
                        >
                          (+
                          <span class="space-x-1">
                            <span
                              :class="{
                                'font-normal italic text-gray-600 line-through':
                                  deliveryDiscount
                              }"
                              >{{
                                subscription.settings.originalDelivery
                                  | formatPounds
                              }}</span
                            >

                            <span v-if="deliveryDiscount">{{
                              subscription.settings.finalDelivery | formatPounds
                            }}</span>
                          </span>
                          delivery)
                        </div>
                        <div
                          v-if="
                            subscription.deliveryTypeFreeUpgrade &&
                            !subscription.isRemote
                          "
                          class="mt-1 text-sm font-normal text-gray-600"
                        >
                          (Incl. FREE upgraded delivery!)
                        </div>
                        <div
                          class="flex items-center rounded pt-4 text-sm text-gray-600 sm:pb-2"
                          v-if="subscription.active"
                        >
                          <div v-if="!subscription.isPausedIndefinitely">
                            You have
                            <span
                              class="border-b-2 border-teal-300 bg-teal-100 px-1 py-1 font-semibold text-gray-800"
                              >{{ daysLeft(getPaymentDate()) }}
                              {{
                                daysLeft(getPaymentDate()) | pluralize('day')
                              }}</span
                            >
                            left if you wish to adjust before we send out your
                            {{
                              subscription.isOnTrial
                                ? 'first months box.'
                                : 'next box.'
                            }}
                          </div>

                          <div v-else>
                            Ready to unpause and restart your subscription? Feel
                            free to
                            <router-link
                              :to="{
                                name: 'adjust pricing',
                                params: { plans: subscription }
                              }"
                              class="border-b border-dashed border-teal-400 text-teal-400"
                              >adjust your plan</router-link
                            >
                            before you do!
                          </div>
                        </div>
                      </div>
                      <div v-else class="flex h-full flex-col">
                        <div class="mb-8 text-xl font-semibold">
                          Welcome back, ready to restart your subscription?
                        </div>
                        <div class="flex-grow">
                          Your subscription is currently inactive.
                        </div>
                        <div class="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2">
                          <div
                            class="block w-full"
                            @click="
                              sendEvent({
                                event: 'Adjust Subscription',
                                properties: {
                                  location: $route.name,
                                  type: 'EVT'
                                }
                              })
                            "
                          >
                            <router-link
                              tag="button"
                              :to="{
                                name: 'adjust pricing',
                                params: { plans: subscription },
                                query: { restart: true }
                              }"
                              class="block w-full cursor-pointer items-center justify-center rounded bg-yellow-200 px-4 py-4 text-center font-semibold hover:bg-yellow-200 sm:mt-0"
                            >
                              Restart Subscription
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="subscription.active" class="w-full sm:w-auto">
                    <div
                      class="mt-8 flex w-full flex-col space-y-4 sm:mt-0 sm:w-48"
                    >
                      <div
                        class="block w-full"
                        @click="
                          sendEvent({
                            event: 'Adjust Subscription',
                            properties: {
                              location: $route.name,
                              type: 'EVT'
                            }
                          })
                        "
                      >
                        <router-link
                          tag="button"
                          :to="{
                            name: 'adjust pricing',
                            params: { plans: subscription }
                          }"
                          class="border-gunmetal-400 text-gunmetal-600 block w-full appearance-none rounded bg-teal-400 px-4 py-4 text-center font-semibold text-white hover:bg-teal-300"
                        >
                          <font-awesome-icon
                            :icon="['far', 'sliders']"
                            class="mr-4"
                          />
                          Adjust
                        </router-link>
                      </div>
                      <div
                        v-if="
                          hasSubscription &&
                          !subscription.payday &&
                          !subscription.isOnTrial
                        "
                      >
                        <pause-button :subscription="subscription" />
                      </div>
                      <div v-if="subscription.payday">
                        <skip-button :subscription="subscription" />
                      </div>

                      <div
                        v-if="
                          subscription.isOnTrial && subscription.canExtendTrial
                        "
                      >
                        <pause-button
                          :subscription="subscription"
                          label="Extend Trial"
                        />
                      </div>

                      <!-- <div
                    v-if="!subscription.isPausedIndefinitely"
                    class="mt-4 px-4 text-sm text-gray-500 "
                  >
                    <extend-pause-button
                      v-if="!subscription.isOnTrial"
                      :subscription="subscription"
                    />
                  </div> -->
                    </div>
                  </div>
                </div>
                <div class="py-4 pb-0">
                  <food-forward
                    v-if="subscription.active"
                    :subscription="subscription"
                    class="col-span-3 border-t-2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <credit-balance />
          </div>
          <div
            class="col-span-3 flex flex-col overflow-hidden rounded-lg border-2 sm:col-span-3"
          >
            <div>
              <subscription-banner />
              <div
                class="flex grid grid-cols-3 flex-col items-stretch justify-center gap-4 sm:flex-row"
              >
                <div
                  class="col-span-4 flex w-full flex-col overflow-hidden sm:col-span-4"
                >
                  <div
                    class="w-full p-6 px-4 pb-2 pt-4 text-base font-semibold"
                  >
                    Cats in your subscription
                    <span class="font-normal text-gray-500"
                      >({{ subscription.items.length }})</span
                    >
                  </div>
                  <div
                    v-if="subscription.items"
                    class="divide-y-2 divide-dashed"
                  >
                    <div
                      v-for="(cat, index) in subscription.items"
                      class="w-full"
                      :key="'cat-' + index"
                    >
                      <cat-subscription-mini
                        :position="index"
                        :cat="cat"
                        :plan="cat"
                        :isLoading="isLoading"
                        :isActive="cat.active && subscription.active"
                        class="text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div
                  key="add-cat"
                  class="col-span-4 flex-grow border-t-2 border-dashed p-2 sm:col-span-4 sm:flex"
                  @click="showModal('', 'AddCat', subscription)"
                >
                  <div
                    class="flex cursor-pointer items-center justify-center space-x-4 rounded-lg bg-gray-200 p-6 py-3 text-gray-500 hover:cursor-pointer hover:bg-gray-300 sm:mx-auto sm:inline-flex"
                  >
                    <font-awesome-icon :icon="['far', 'plus']" />
                    <div>Add another cat</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="subscription.active" class="border-t-2">
              <div
                class="flex cursor-pointer items-center space-x-4 p-4 py-3 text-base font-semibold hover:bg-gray-100"
                @click="showPreviousBoxes = !showPreviousBoxes"
              >
                <div class="flex-grow">Your Previous Boxes</div>
                <div class="text-gray-500">
                  <font-awesome-icon
                    :icon="[
                      'far',
                      showPreviousBoxes ? 'chevron-up' : 'chevron-down'
                    ]"
                  />
                </div>
              </div>
              <div v-show="showPreviousBoxes">
                <div class="divide-y-2">
                  <history-item
                    :key="`latest-history-${item.invId}`"
                    v-for="item in latestHistory"
                    is-inline
                    :item="item"
                  />
                </div>
                <div class="flex justify-center py-4">
                  <router-link
                    :to="{ name: 'order history' }"
                    exact
                    class="text-center text-sm text-gray-600 underline"
                  >
                    View all orders &rarr;
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex h-72 w-full flex-col items-center justify-center space-y-4 rounded-lg border-2 px-8 text-center"
        >
          <div>
            Hmmmm... looks like there was an issue loading your subscription. No
            worries, you can refresh your page to try again.
          </div>
          <div>
            Get in touch at
            <a href="mailto:team@thecoolcatclub.com">team@thecoolcatclub.com</a>
            if the issue persists.
          </div>
        </div>
      </div>
      <div
        class="rounded-lg border-2 border-gray-400 bg-gray-100 p-8 text-center"
        key="not_subscribed"
        v-else
      >
        <div>
          <div
            class="mb-4 font-merri text-xl font-semibold text-gray-800"
            :class="{ 'mt-4': user.hadSampleBox }"
          >
            {{
              !user.hadSampleBox
                ? 'Dip your paws in the water...'
                : 'You currently have no subscription.'
            }}
          </div>

          <div class="mt-8">
            <div
              v-if="!user.hadSampleBox"
              class="w-full cursor-pointer sm:mx-auto sm:w-72"
            >
              <div
                class="mb-8 block flex h-16 flex-col items-center justify-center rounded bg-yellow-200 px-4 text-center font-merri text-lg hover:cursor-pointer hover:bg-yellow-200 sm:h-16"
                @click="startSub(true)"
              >
                <div>{{ ctaTextTaster }}</div>
              </div>
              <div class="p-2 py-0">
                <div class="mb-2 flex">
                  <font-awesome-icon
                    class="mr-3 mt-1"
                    :icon="['far', 'check']"
                  />Full of our most popular flavours
                </div>
                <div class="mb-2 flex">
                  <font-awesome-icon
                    class="mr-3 mt-1"
                    :icon="['far', 'check']"
                  />Adjust or cancel anytime
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import utils from '@/utils';
import { mapState, mapGetters, mapActions } from 'vuex';

import {
  addDays,
  isSameDay,
  fromUnixTime,
  format,
  differenceInDays
} from 'date-fns';
import { slice } from 'lodash/fp';
import CatSubscriptionMini from '@/components/CatSubscriptionMini';
import FoodForward from '@/components/FoodForward';
import PauseButton from '@/components/PauseButton';
import HistoryItem from '@/components/order/HistoryItem';
import SkipButton from '@/components/SkipButton';
import SubscriptionBanner from '@/components/subscription/SubscriptionBanner';
// import ExtendPauseButton from '@/components/ExtendPauseButton';
import SubscriptionError from '@/components/subscription/SubscriptionError';
import CreditBalance from '@/components/CreditBalance';
import HistoryTimeline from '../order/HistoryTimeline.vue';

export default {
  data() {
    return {
      purchase: null,
      subscriptionError: null,
      isLoading: true,
      isSuccessUpdate: this.$route.params.isSuccessUpdate,
      showPreviousBoxes: false
    };
  },
  components: {
    CatSubscriptionMini,
    HistoryItem,
    CreditBalance,
    FoodForward,
    PauseButton,
    SkipButton,
    SubscriptionBanner,
    SubscriptionError,
    HistoryTimeline
    // ExtendPauseButton
  },
  computed: {
    ...mapState({
      updateSubscription: 'updateSubscription',
      ctaText: (state) => state.core.ctaText,
      ctaTextTaster: (state) => state.core.ctaTextTaster
    }),
    ...mapGetters({
      subscription: 'getCurrentSubscription',
      user: 'getCurrentUser',
      latestOrder: 'latestOrder',
      history: 'orderHistory'
    }),
    canUpgrade: function () {
      return this.$store.state.core.user.canUpgrade;
    },
    samplePrice: function () {
      return this.$store.state.core.samplePrice;
    },
    hadSubscription() {
      return this.user.hadSubscription;
    },
    hasSubscription() {
      return this.user.hasSubscription;
    },
    hadSampleBox() {
      return this.user.hadSampleBox;
    },
    shippedPeriodOver() {
      const period = addDays(new Date(this.latestOrder.shippedAt), 3);

      return isSameDay(new Date(), new Date(period));
    },
    hasCredit() {
      return this.subscription.credit > 0;
    },
    hasCoupon() {
      return this.subscription.coupons.length > 0;
    },
    deliveryDiscount() {
      return (
        this.subscription.settings.originalDelivery !==
        this.subscription.settings.finalDelivery
      );
    },
    discountMessage() {
      if (this.hasCoupon && this.hasCredit) {
        return 'Coupon & Credit applied!';
      } else if (this.hasCoupon) {
        return 'Coupon applied!';
      } else if (this.hasCredit) {
        return 'Credit applied!';
      }

      return null;
    },
    creditBalance() {
      return (this.subscription.credit / 100).toFixed(2);
    },
    coupons() {
      return utils.formatCoupons(this.subscription.coupons);
    },
    creditAmount() {
      const deliveryCost = this.subscription?.deliveryTypeIsPaid
        ? this.subscription.isRemote
          ? 299
          : 199
        : 0;

      let creditValue = this.subscription.totalCost - this.subscription.credit;
      if (
        this.subscription.credit >
        this.subscription.totalCost - deliveryCost
      ) {
        creditValue =
          this.subscription.totalCost - deliveryCost - this.subscription.credit;
      }

      if (creditValue < 0) {
        return utils.getDisplayCost(0, 0, true);
      } else {
        return utils.getDisplayCost(creditValue, deliveryCost, true);
      }

      // return this.subscription.settings.subscription;
    },
    creditDelivery() {
      const deliveryCost = this.subscription?.deliveryTypeIsPaid
        ? this.subscription.isRemote
          ? 299
          : 199
        : 0;
      const subCost = this.subscription.totalCost - deliveryCost;
      const creditValue = subCost - this.subscription.credit;

      const delVal = deliveryCost - creditValue * -1;
      if (delVal < deliveryCost) {
        if (deliveryCost - creditValue * -1 <= 0) {
          return 'FREE';
        } else {
          return utils.getDisplayCost(deliveryCost, creditValue * -1, true);
        }
      }
      return false;
    },
    latestHistory() {
      return slice(0, 3, this.history);
    },
    activeDelivery() {
      const activeDelivery = this.subscription.deliveryType.prices.find(
        (p) => p.id === this.subscription.deliveryTypeUserSelected
      );

      return activeDelivery;
    },
    formattedSubscriptionCost() {
      // const delivery = this.subscription?.deliveryType?.prices.find(
      //   o => o.id === this.subscription.deliveryTypeUserSelected
      // );

      const paidCost = this.subscription.deliveryTypeIsPaid
        ? this.subscription.isRemote
          ? 299
          : 199
        : 0;
      // remove the check for total cost if fix is put in place
      return utils.getDisplayCost(this.subscription.totalCost, paidCost, true);
    },
    hasFreeSamples() {
      return this.subscription?.areSamplesFree;
    },

    formattedSampleCost() {
      return utils.formattedSamplePrice(this.subscription?.bonusFood?.length);
    }
  },
  watch: {
    updateSubscription: function (payload) {
      if (payload.forward) {
        this.isSuccessUpdate = true;
      }
      if (payload) {
        this.isSuccessUpdate = true;
      }
      this.getSubscription();
    }
  },
  methods: {
    ...mapActions({
      sendEvent: 'sendMpEvent'
    }),
    getSubscription: function () {
      this.isLoading = true;
      this.subscriptionError = false;
      this.$store
        .dispatch('getSubscription')
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch('getOrderHistory');
        })
        .catch((error) => {
          this.isLoading = false;
          this.subscriptionError = true;
          this.error = error;
        });
    },
    getDispatchDate: function () {
      const date = fromUnixTime(this.subscription.nextdispatchdate / 1000);
      return format(date, 'do MMMM, yyyy');
    },
    getPaymentDate: function () {
      const date = fromUnixTime(this.subscription.nextbilldate / 1000);
      return format(date, 'do MMMM, yyyy');
    },
    daysLeft: function () {
      const date = fromUnixTime(this.subscription.nextbilldate / 1000);

      return differenceInDays(date, new Date());
    },

    setTaster(label, isTaster) {
      this.$store.dispatch('sendMpEvent', {
        event: this.ctaTextTaster,
        properties: {
          location: this.$route.name,
          section: 'dashboard',
          type: 'EVT'
        }
      });
      this.$store.commit('set_as_taster', isTaster);
    },

    showModal: function (title, component, data) {
      this.$store.commit('show_modal', {
        title: title,
        component: component,
        data: data,
        props: { isFull: true }
      });
    },
    getSample: function () {
      this.$store.dispatch('sendMpEvent', {
        event: this.ctaTextTaster,
        properties: {
          location: this.$route.name,
          section: 'dashboard',
          type: 'EVT'
        }
      });

      if (this.$gtag) {
        this.$gtag.event('begin_checkout', {
          items: [
            {
              id: 0,
              name: 'sample pack',
              price: this.samplePrice
            }
          ]
        });
      }

      this.$router.push({
        name: 'subscribe delivery',
        params: {
          samplePack: true
        }
      });
    },
    startSub: function (isTaster) {
      this.$store.dispatch('sendMpEvent', {
        event: isTaster ? this.ctaTextTaster : 'Start Subscription',
        properties: {
          location: this.$route.name,
          section: 'dashboard',
          type: 'EVT'
        }
      });
      this.$store.commit('set_as_taster', isTaster);

      if (this.hadSubscription) {
        this.$router.push({
          name: 'subscribe pricing',
          params: {
            subscription: this.subscription,
            step: 'summary',
            hadSubscription: true
          },
          query: {
            fromCancel: true
          }
        });
      } else {
        this.$router.push({
          name: 'subscribe cats',
          params: {
            subscription: this.subscription
          }
        });
      }
    }
  },

  mounted() {
    this.getSubscription();
  }
};
</script>

<style lang="scss" scoped></style>
